<template>
  <div v-show="banners.length > 0" class="about">
    <div class="container">
      <div class="about_images">
        <main-about-desktop class="about__images-desktop" :banners="banners"></main-about-desktop>
        <main-about-mobile class="about__images-mobile" :banners="banners"></main-about-mobile>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/main/about.scss';
import 'sass/index.scss';

import {computed} from '#imports';
import {useRootStore} from '@/stores/root';
import MainAboutDesktop from './desktop.vue';
import MainAboutMobile from './mobile.vue';

const rootStore = useRootStore();

const banners = computed(() => rootStore.mainPageData?.banners || []);
</script>

<style scoped>
.about__images-mobile {
  display: none;
}

@media (width <= 1000px) {
  .about__images-desktop {
    display: none;
  }

  .about__images-mobile {
    display: block;
  }
}
</style>
