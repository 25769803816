<template>
  <div>
    <slider></slider>
    <slider-small :width="width"></slider-small>
    <main-about></main-about>
    <!-- <div class="calendar-block">
      <div class="container">
        <h2 class="text-uppercase mb-30">Календарь мероприятий</h2>
        <calendar
          v-if="rootStore.calendarData"
          :events="rootStore.calendarData"
          hide-title
        ></calendar>
      </div>
    </div> -->
    <main-news></main-news>
  </div>
</template>

<script setup lang="ts">
import 'sass/index.scss';

import {computed, useAsyncData} from '#imports';
// import Calendar from '@/components/calendar/index.vue';
import MainAbout from '@/components/main/about/index.vue';
import MainNews from '@/components/main/news/index.vue';
import Slider from '@/components/slider/index.vue';
import SliderSmall from '@/components/slider/small.vue';
import {useApiMeta} from '@/composables/useMeta';
import {useInfoStore} from '@/stores/info';
import {useRootStore} from '@/stores/root';

const storeInfo = useInfoStore();
const rootStore = useRootStore();

const width = computed(() => rootStore.width || 1400);

void useAsyncData(() => storeInfo.getNews('main'));
void useAsyncData(() => rootStore.fetchMainPageData());
void useAsyncData(() => rootStore.fetchCalendarData());

useApiMeta();
</script>
