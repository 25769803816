<template>
  <div class="news">
    <div class="container">
      <div class="news__wrapper">
        <div class="news__main">
          <div class="news__title_wrapper">
            <h2 class="text-uppercase">База знаний</h2>
            <base-button text="Все статьи и обзоры" arrow url="/info"></base-button>
          </div>
          <div class="news__main_wrapper">
            <base-main-card v-if="mainArticle" :item="mainArticle"></base-main-card>
            <div v-if="subArticles.length" class="sub_cards sub_cards_infocenter">
              <infocenter-sub-slider :sub-news="subArticles"></infocenter-sub-slider>
            </div>
          </div>
        </div>
        <infocenter-other-news :other-news="otherNews"></infocenter-other-news>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'sass/main/news.scss';
import 'sass/index.scss';
import 'sass/utilites.scss';

import {computed} from '#imports';
import BaseButton from '@/components/Base/Button.vue';
import BaseMainCard from '@/components/Base/mainCard.vue';
import InfocenterOtherNews from '@/components/Infocenter/otherNews.vue';
import InfocenterSubSlider from '@/components/Infocenter/subSlider.vue';
import {useInfoStore} from '@/stores/info';

const infoStore = useInfoStore();

const articlesList = computed(() => infoStore.articles);
const otherNews = computed(() => infoStore.otherNews);

const mainArticle = computed(() => articlesList.value.at(0));
const subArticles = computed(() => articlesList.value.slice(1));
</script>
