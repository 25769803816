<template>
  <div class="container container_small_slider">
    <div class="small_slider">
      <div class="small_slider_wrapper">
        <a href="#" @click.prevent="smallSlider?.slidePrev()">
          <left-arrow-svg
            class="small_slider__btn small_slider__btn_prev"
            :class="{small_slider__btn_disabled: smallSlider?.isBeginning}"
          ></left-arrow-svg>
        </a>

        <swiper
          class="small_slider_main"
          :slides-per-view="countCurrentElements"
          :slides-per-group="1"
          loop-fill-group-with-blank
          :breakpoints="{
            521: {slidesPerGroup: 2},
          }"
          @swiper="onSwiper($event)"
        >
          <swiper-slide v-for="item in smallSliderList" :key="item.name">
            <small-item :item="item"></small-item>
          </swiper-slide>
        </swiper>

        <a href="#" @click.prevent="smallSlider?.slideNext()">
          <left-arrow-svg
            class="small_slider__btn small_slider__btn_next"
            :class="{small_slider__btn_disabled: smallSlider?.isEnd}"
            style="transform: rotate(180deg)"
          ></left-arrow-svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css';
import 'sass/main/slider/small.scss';

import {type Swiper as SwiperClass} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {computed, ref} from '#imports';
import {useRootStore} from '@/stores/root';
import LeftArrowSvg from 'svg/slider/leftArrow.vue';
import SmallItem from './smallItem.vue';

const props = defineProps({
  width: {type: Number, required: false},
});

const rootStore = useRootStore();

const smallSliderList = computed(() => rootStore.mainPageData?.icons || []);

const smallSlider = ref<SwiperClass>();
const onSwiper = (swiper: SwiperClass) => {
  smallSlider.value = swiper;
};

const currWidth = computed(() => props.width);

const countCurrentElements = computed(() => {
  const width = currWidth.value;

  if (!width || width > 1300) {
    return 6;
  }
  if (width > 992) {
    return 4;
  }
  if (width > 640) {
    return 3;
  }
  if (width > 520) {
    return 2;
  }
  return 1;
});
</script>

<style lang="scss" scoped>
.container_small_slider {
  @media (width <= 620px) {
    padding: 0;
  }
}
</style>
