<template>
  <div class="container">
    <swiper
      class="main_swiper"
      effect="fade"
      :modules="[Pagination, EffectFade]"
      :pagination="{clickable: true}"
      :loop="sliderList.length > 1"
      :space-between="30"
    >
      <swiper-slide v-for="(slide, idx) in sliderList" :key="idx" class="slide">
        <full-item :slide="slide"></full-item>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import 'sass/index.scss';
import 'sass/fonts.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'sass/main/slider/index.scss';

import {EffectFade, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {computed} from '#imports';
import {useRootStore} from '@/stores/root';
import FullItem from './item.vue';

const rootStore = useRootStore();

const sliderList = computed(() => rootStore.mainPageData?.sliders || []);
</script>
