<template>
  <component
    :is="item.url ? NuxtLink : 'span'"
    :to="removeLastSlashFromLink(item.url)"
    class="small_slider__item"
    @mouseleave="colorSvg = '#252525'"
    @mousemove="colorSvg = '#ff6245'"
  >
    <div class="small_slider__icon">
      <img :src="item.svg" :alt="item.name" />
    </div>
    <div class="small_slider__text">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span class="swiper-no-swiping" v-html="item.name"></span>
    </div>
  </component>
</template>

<script setup lang="ts">
import 'sass/main/slider/small-item.scss';

import {NuxtLink} from '#components';
import {type PropType, ref} from '#imports';
import type {IIconResponse} from '@/types/store/store';
import {removeLastSlashFromLink} from '@/utils';

defineProps({
  item: {type: Object as PropType<IIconResponse>, required: true},
});

const colorSvg = ref('#252525');
</script>
